:root {
  --pink: #f000cc;
  --purple: #9500ff;
  --green: #74ee15;
  --yellow: #eeff00;
  --light-blue: #4deeea;
  --blue: #001eff;
  --orange: #ff7000;
  --red: #ff1818;
}

.neon {
  position: relative;
  padding: 20px 40px;
  border-radius: 10px;
  border: 3px solid #fff7;
  outline: solid;
  outline-offset: 3px;
  outline-color: #fff7;
  color: #fffc;
  cursor: pointer;
  transition: all 0.2s ease;

  &:has(.icon) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &.on {
    // animation: neonBlink 10s ease infinite;

    border: 3px solid #fff;
    outline-color: #fff;
    color: #fff;
    box-shadow:
      0 0 2px #fff,
      0 0 5px var(--color),
      0 0 30px var(--color),
      inset 0 0 1px #fff,
      inset 0 0 20px var(--color);
    text-shadow:
      0 0 2px #fff,
      0 0 5px var(--color),
      0 0 10px var(--color),
      // 0 0 20px var(--color)
    ;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: var(--color);
      transition: all .2s ease;
    }

    &:hover {
      // transform: scale(1.02);
      box-shadow:
        0 0 2px #fff,
        0 0 5px var(--color),
        0 0 10px var(--color),
        0 0 20px var(--color),
        0 0 40px var(--color),
        inset 0 0 2px #fff,
        inset 0 0 5px var(--color),
        inset 0 0 10px var(--color),
        inset 0 0 20px var(--color);

      &:before {
        opacity: 0.05;
      }
    }
  }

  &.pink {
    --color: var(--pink);
    animation-delay: 250ms;
  }

  &.green {
    --color: var(--green);
    animation-delay: 1125ms;
  }

  &.yellow {
    --color: var(--yellow);
    animation-delay: 500ms;
  }

  &.lightBlue {
    --color: var(--light-blue);
    animation-delay: 875ms;
  }

  &.blue {
    --color: var(--blue);
    animation-delay: 750ms;
  }

  &.orange {
    --color: var(--orange);
    animation-delay: 1000ms;
  }

  &.red {
    --color: var(--red);
    animation-delay: 625ms;
  }

  &.purple {
    --color: var(--purple);
    animation-delay: 375ms;
  }

  .icon {
    font-size: 60px;

    display: block;
    margin: 0 auto;

    &.on {
      filter: drop-shadow(0 0 1px #ffffff) drop-shadow(0 0 5px var(--color)) drop-shadow(0 0 10px var(--color));
    }
  }
}

@keyframes neonBlink {

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    border: 3px solid #fff;
    outline-color: #fff;
    color: #fff;
    box-shadow:
      0 0 2px #fff,
      0 0 5px var(--color),
      0 0 10px var(--color),
      0 0 20px var(--color),
      0 0 40px var(--color),
      inset 0 0 2px #fff,
      inset 0 0 5px var(--color),
      inset 0 0 10px var(--color),
      inset 0 0 20px var(--color);
    text-shadow:
      0 0 2px #fff,
      0 0 5px var(--color),
      0 0 10px var(--color),
      0 0 20px var(--color);
  }

  20%,
  24%,
  55% {
    box-shadow:
      0 0 2px #ffffff,
      0 0 5px var(--color),
      0 0 12px var(--color),
      0 0 30px var(--color),
      inset 0 0 2px #ffffff,
      inset 0 0 5px var(--color),
      inset 0 0 12px var(--color),
      inset 0 0 22px var(--color);
    text-shadow:
      0 0 1px #ffffff,
      0 0 5px var(--color),
      0 0 12px var(--color),
      0 0 22px var(--color);
  }
}

@media screen and (max-width: 768px) {
  .neon {

    background-image: none;

    .icon {
      font-size: 50px;
    }
  }
}
