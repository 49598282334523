.about-card {
  position: relative;
  height: 100%;



  h1,
  h2,
  p {
    position: relative;
    z-index: 2;
  }

  h1 {
    margin-bottom: 5px;
  }

  .presentation {
    margin-top: 20px;
    width: 70%;
  }

  .button {
    position: absolute;
    top: 0px;
    right: -15px;
    width: fit-content;
    font-size: 14px;
    transition: 0.3s;

    svg {
      font-size: 20px;
      transition: 0.3s;

      &.on {
        filter: drop-shadow(0 0 5px var(--pink)) drop-shadow(0 0 10px var(--pink));
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .about-card {
    .presentation {
      width: 100%;
    }
  }
}
