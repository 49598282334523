.birthdayCard {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: 30px;
    }
  }

}
