.portfolio {
  h1,
  h2 {
    filter: drop-shadow(0 0 5px var(--blue)) drop-shadow(0 0 10px var(--blue));
  }

  .selector {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 30px;

    & p {
      font-size: 30px;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        filter: drop-shadow(0 0 5px var(--blue))
          drop-shadow(0 0 10px var(--blue)) drop-shadow(0 0 12px var(--blue));
      }
    }

    & .selected {
      filter: drop-shadow(0 0 2px var(--blue)) drop-shadow(0 0 5px var(--blue))
        drop-shadow(0 0 8px var(--blue));
    }
  }

  h2 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .background {
    width: 100vw;
    height: 100vh;
    background-color: #000d;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 1;
  }

  .projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    gap: 40px;
    margin-bottom: 60px;

    .project {
      position: relative;
      padding: 15px;
      overflow: hidden;
      cursor: pointer;
      width: 250px;
      border: 3px solid white;
      border-radius: 10px;
      box-shadow:
        0 0 5px var(--color),
        0 0 10px var(--color),
        0 0 20px var(--color),
        inset 0 0 5px var(--color),
        inset 0 0 10px var(--color),
        inset 0 0 20px var(--color);

      &.on {
        &.pink {
          --color: var(--pink);
        }

        &.green {
          --color: var(--green);
        }

        &.yellow {
          --color: var(--yellow);
        }

        &.lightBlue {
          --color: var(--light-blue);
        }

        &.blue {
          --color: var(--blue);
        }

        &.orange {
          --color: var(--orange);
        }

        &.red {
          --color: var(--red);
        }

        &.purple {
          --color: var(--purple);
          animation-delay: 175ms;
        }
      }

      img {
        width: 100%;
        height: auto;
        transition: 0.5s;
        filter: drop-shadow(0 0 1px white) drop-shadow(0 0 2px white)
          drop-shadow(0 0 5px var(--color)) drop-shadow(0 0 10px var(--color));
      }

      .title {
        position: absolute;
        width: 90%;
        top: -50%;
        transition: 0.3s;
        z-index: 10;
      }

      .techs {
        position: absolute;
        width: 100%;
        bottom: -50%;
        right: 15px;
        transition: 0.5s;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        font-size: 30px;
        transition: 0.3s;
        z-index: 10;
      }

      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
          180deg,
          transparent -10%,
          var(--color) 115%
        );
        transition: 0.3s;
        opacity: 0;
      }

      &:hover {
        .cover {
          opacity: 0.7;
        }

        .title {
          top: 15px;
        }

        .techs {
          bottom: 15px;
        }

        img {
          scale: 1.8;
          opacity: 0.2;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .portfolio {
    .projects {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .portfolio {
    .selector {
      gap: 10px;

      p {
        font-size: 25px;
      }
    }

    .projects {
      .project {
        width: 200px;

        &:hover {
          .cover {
            opacity: 0.7;
          }

          .title {
            top: -50%;
          }

          .techs {
            bottom: -50%;
          }

          img {
            scale: 1.8;
            opacity: 0.2;
          }
        }
      }
    }
  }
}
