.backdrop {
  position: fixed;

  top: 12%;
  bottom: 10%;
  left: 10%;
  right: 10%;

  background: rgba(0, 0, 0, .97);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  border: 5px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 20px var(--blue), inset 0 0 20px var(--blue);

  padding: 50px 40px;

  z-index: 10;

  // &::after {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   background-color: red;
  //   position: absolute;
  // }

  & .closeButton {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    font-size: 35px;
    color: white;
    filter: drop-shadow(0 0 1px var(--red)) drop-shadow(0 0 5px var(--red)) drop-shadow(0 0 10px var(--red));
  }

  & .nextButton {
    position: absolute;
    cursor: pointer;
    top: 250px;
    right: 20px;
    font-size: 35px;
    color: white;
    filter: drop-shadow(0 0 1px var(--blue)) drop-shadow(0 0 5px var(--blue)) drop-shadow(0 0 10px var(--blue));
  }

  & .prevButton {
    position: absolute;
    cursor: pointer;
    top: 250px;
    left: 20px;
    font-size: 35px;
    color: white;
    filter: drop-shadow(0 0 1px var(--blue)) drop-shadow(0 0 5px var(--blue)) drop-shadow(0 0 10px var(--blue));
  }

  & .smallPic {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    filter: drop-shadow(0 0 5px var(--blue)) drop-shadow(0 0 10px var(--blue));
  }

  & .enlargedPic {
    width: 90%;
    max-width: 700px;
    border-radius: 15px;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  h2 {
    margin-top: 30px;
  }

  & .content {
    width: 80%;
    margin: 0 auto
  }

  &::-webkit-scrollbar {
    width: 10px;
    margin: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--blue);
    border-radius: 100px;

    &:hover {
      background: var(--blue);
    }
  }

  & .techs {
    width: 80%;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    & .tech {
      background-color: var(--blue);
      padding: 5px 15px;
      border-radius: 20px;
    }
  }

  & .links {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 30px;

    & .link {
      display: flex;
      align-items: center;
      gap: 10px;

      transition: .3s;
      filter: drop-shadow(0 0 1px var(--blue));

      &:hover {
        filter: drop-shadow(0 0 5px var(--blue)) drop-shadow(0 0 10px var(--blue));
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .backdrop {
    border: 3px solid #ccc;
    padding: 30px;

    & .closeButton {
      top: 5px;
      right: 5px;
      font-size: 25px;
    }

    & .nextButton {
      font-size: 25px;
      right: 5px;
    }

    & .prevButton {
      font-size: 25px;
      left: 5px;
    }

    & .smallPic {
      top: 5px;
      left: 5px;
      width: 25px;
    }

    & .enlargedPic {
      width: 100%;
      border-radius: 10px;
    }

    & .content {
      width: 100%;
    }

    & .techs {
      justify-content: center;
      width: 100%
    }

    & .links {
      width: 80%;
      margin-top: 10px;
      gap: 10px;
      font-size: 25px;

      span {
        display: none;
      }
    }
  }
}
