.projects-card {
  position: relative;

  .button {
    position: absolute;
    top: 0px;
    right: -15px;
    width: fit-content;
    font-size: 14px;
    transition: 0.3s;

    svg {
      font-size: 20px;
      transition: 0.3s;

      &.on {
        filter: drop-shadow(0 0 5px var(--blue)) drop-shadow(0 0 10px var(--blue));
      }
    }
  }
}

// @media screen and (max-width: 768px) {
//   .projects-card {
//     .examples {
//       justify-content: space-around;
//       gap: 5px;
//       width: 100%;

//       .project-image {
//         width: 50px;
//         height: 50px;

//         &.on {
//           filter: brightness(0) drop-shadow(0 0 2px white) drop-shadow(0 0 4px var(--blue)) drop-shadow(0 0 5px var(--blue));
//         }
//       }
//     }
//   }
// }
