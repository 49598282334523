.discordCard {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-transform: capitalize;

    span {
      font-size: 30px;
    }
  }
}
