.timeline-item {
  width: calc(50% + 4px);
  display: flex;
  justify-content: space-between;
  clear: both;

  &.on {
    &.pink {
      --color: var(--pink);
    }

    &.green {
      --color: var(--green);
    }

    &.yellow {
      --color: var(--yellow);
    }

    &.lightBlue {
      --color: var(--light-blue);
    }

    &.blue {
      --color: var(--blue);
    }

    &.orange {
      --color: var(--orange);
    }

    &.red {
      --color: var(--red);
    }

    &.purple {
      --color: var(--purple);
    }
  }

  & .timeline-header {
    & h3 {
      filter: drop-shadow(0 0 5px var(--color))
        drop-shadow(0 0 10px var(--color));
      border: 2px solid white;
      border-radius: 10px;
      padding: 5px 20px;
      display: inline-block;
    }

    & p {
      width: fit-content;
      margin-top: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid white;

      span {
        font-weight: 700;
      }
    }
  }

  & .timeline-body {
    padding-left: 15px;
    padding-right: 15px;
    & p {
      font-size: 16px;
      color: #bbb;
    }
  }

  & .timeline-footer {
    width: fit-content;
    margin-top: 10px;
    border-top: 1px solid white;
  }

  &.timeline-block-right {
    float: right;
    & ul {
      & li {
        margin-bottom: 10px;
        display: flex;
        // justify-content: space-between;
        // clear: both;
      }
    }
  }

  &.timeline-block-left {
    float: left;
    direction: rtl;
    & ul {
      text-align: right;

      & li {
        margin-bottom: 10px;
        display: flex;
      }
    }

    & .timeline-header {
      text-align: right;

      & h3 {
        margin-bottom: 5px;
      }

      & h4 {
        margin-bottom: 10px;
      }
    }
    & .timeline-footer {
      text-align: right;
    }
  }

  & .marker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    border: 1px solid var(--color);
    margin-top: 20px;
    z-index: 5;
    filter: drop-shadow(0 0 5px var(--color)) drop-shadow(0 0 10px var(--color))
      drop-shadow(0 0 15px var(--color));
  }

  & .timeline-panel {
    width: 95%;
    padding: 0 10px;

    & .timeline-body {
      width: 95%;
    }
  }
}

@media screen and (max-width: 768px) {
  .timeline-item {
    width: 100%;
    margin-bottom: 30px;

    &.timeline-block-right {
      float: none;
    }

    &.timeline-block-left {
      float: none;
      direction: ltr;

      & ul {
        text-align: left;
      }

      & .timeline-header,
      & .timeline-footer {
        text-align: left;
      }
      & .timeline-panel {
        & .timeline-body {
          width: 100%;
        }
      }
    }
  }
}
