footer {
  position: fixed;
  bottom: 0;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // border-top: 2px solid white;
  // border-right: 2px solid white;
  box-shadow: 0 0 5px white, 0 0 10px white;
  background-color: #000d;
  z-index: 10;
  border-top-right-radius: 10px;
  backdrop-filter: blur(5px);

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    & .icon {
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;

      &.purple {
        --color: var(--purple);
      }

      &.lightBlue {
        --color: var(--light-blue);
      }

      &.yellow {
        --color: var(--yellow);
      }

      &.red {
        --color: var(--red);
      }

      &.green {
        --color: var(--green);
      }

      &.on {
        filter: drop-shadow(0 0 1px white) drop-shadow(0 0 5px var(--color)) drop-shadow(0 0 10px var(--color));

        &:hover {
          filter: drop-shadow(0 0 1px #ffffff) drop-shadow(0 0 5px #fff) drop-shadow(0 0 10px #fff);
        }
      }
    }
  }
}
