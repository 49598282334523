#grid {
  padding: 40px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr 1fr auto 1fr;

  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  #about {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
  }

  #projects {
    grid-column: 1/5;
  }

  #blog {
    grid-column: 1/5;
  }

  .toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    transition: 0.2s;
    cursor: pointer;

    .switch {
      padding: 10px;
      border-radius: 100%;
      background-color: transparent;
      margin: 2px;
      transition: 0.2s;
      border: 2px solid #ff1818dd;
      box-shadow:
        0 0 5px #ff1818aa,
        0 0 10px #ff1818aa,
        inset 0 0 5px #ff1818aa,
        inset 0 0 10px #ff1818aa;
      color: #ff1818dd;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
    }

    &.toggleOn {
      .switch {
        background-color: #fffe;
        border: 2px solid #fff;
        color: #000;
        animation: blink 10s ease infinite;
      }
    }
  }
}

@keyframes blink {

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  88%,
  90%,
  100% {
    box-shadow:
      0 0 5px #ffffff,
      0 0 10px #ffffff,
      0 0 20px #ffffff;
  }

  20%,
  24%,
  55%,
  89% {
    box-shadow:
      0 0 5px #ffffff,
      0 0 10px #ffffff;
  }
}

@media screen and (max-width: 768px) {
  #grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding: 20px;
    gap: 35px;

    #about {
      grid-column: 1 / 3;
      grid-row: 2 / 4;
    }

    #projects {
      grid-column: 1/3;
    }

    #blog {
      grid-column: 1/3;
    }

    .toggle {
      grid-column: 1 / 3;
      grid-row: 1;
      padding-right: 0;
      margin-bottom: -15px;

      .switch {
        padding: 5px;
        font-size: 15px;
      }
    }
  }
}
