@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,200;1,200&family=Poppins:wght@100;300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #000000ee, #000000ff), url("https://cdn.pixabay.com/photo/2012/03/03/23/06/wall-21534_1280.jpg");
  background-size: 1900px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: white;
}

h1,
h2,
h3,
h4 {
  font-family: "Exo 2", sans-serif;
  font-style: italic;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 18px;
}

p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }
}
