header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 2px solid white;
  box-shadow: 0 0 5px white, 0 0 10px white;
  font-size: 25px;
  background-color: #000d;
  z-index: 20;
  backdrop-filter: blur(3px);

  .backButton {
    transition: all 0.2s ease;

    &:hover {
      filter: drop-shadow(0 0 1px #ffffff) drop-shadow(0 0 5px #fff)
        drop-shadow(0 0 10px #fff);
    }
  }

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    & .icon {
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;

      &.orange {
        --color: var(--orange);
      }

      &.red {
        --color: var(--red);
      }

      &.on {
        filter: drop-shadow(0 0 1px white) drop-shadow(0 0 5px var(--color))
          drop-shadow(0 0 10px var(--color));
        &:hover {
          filter: drop-shadow(0 0 1px #ffffff) drop-shadow(0 0 5px #fff)
            drop-shadow(0 0 10px #fff);
        }
      }
    }
  }
}
